<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
            <v-col cols="12">
                <!-- <v-card class="pt-2 pb-3"> -->
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-account-box"
                                    solo
                                    v-model="customer"
                                    :items="customers"
                                    item-value="value"
                                    item-text="label"
                                    label="Customer Category"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="3">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_from"
                                        label="Start Periode (Request Ship Date)"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        type="month"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="3" >
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        label="End Periode (Request Ship Date)"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        type="month"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-watermark"
                                    clearable
                                    solo
                                    v-model="brand"
                                    :items="brands"
                                    item-value="brand_id"
                                    item-text="descr"
                                    label="Brand"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                    @change="(event) => getColour()"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-coat-rack"
                                    clearable
                                    solo
                                    v-model="coat_mass"
                                    :items="coat_masss"
                                    item-value="coat_mass"
                                    :item-text="item => 'AS' + item.coat_mass"
                                    label="Coat (AS - AZ)"
                                    class="ma-0 pa-0 "
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-star-circle"
                                    clearable
                                    solo
                                    v-model="grade"
                                    :items="grades"
                                    item-value="grade_id"
                                    item-text="grade_id"
                                    label="Grade"
                                    class="ma-0 pa-0 mt-6"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-badge
                                    color="success"
                                    content="GLV Bound"
                                    class="text-right ma-1"
                                >
                                </v-badge>
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-up-down"
                                    clearable
                                    solo
                                    v-model="thick"
                                    :items="thickness"
                                    item-value="thick"
                                    :item-text="item => $store.getters.convertToCurrency(item.thick)"
                                    label="Thickness"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-autocomplete>
                                
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-badge
                                    color="success"
                                    content="GLV Bound"
                                    class="text-right ma-1"
                                >
                                </v-badge>
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-left-right"
                                    clearable
                                    solo
                                    v-model="width"
                                    :items="widths"
                                    item-value="width"
                                    :item-text="item => $store.getters.convertToCurrency(item.width)"
                                    label="Width"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-palette"
                                    clearable
                                    solo
                                    v-model="colour"
                                    :items="colours"
                                    item-value="color_id"
                                    item-text="descr"
                                    label="Colour"
                                    class="ma-0 pa-0 mt-6"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-quality-high"
                                    clearable
                                    solo
                                    v-model="quality"
                                    :items="qualities"
                                    item-value="quality_id"
                                    item-text="quality_id"
                                    label="Quality"
                                    class="ma-0 pa-0 mt-6"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                              <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-7" 
                                color="info" elevation="2" large @click="submit()">Search</v-btn>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="3" md="2" >
                                <v-btn
                                    class="mr-2 rounded-l mt-7"
                                    color="error"
                                    elevation="2"
                                    large
                                    @click="submit()"
                                >
                                    <v-icon dark>
                                        mdi-magnify
                                    </v-icon>
                                    Search
                                </v-btn>
                            </v-col> -->
                        </v-row>
                        
                    </v-container>
                <!-- </v-card> -->
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="12">
                <v-card id="sumScShipmentAnalysis">
                    <v-card-text>
                        <h6 class="indigo-text m-0">Query Result: </h6>
                        <div id="container1" style="height: 370px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card style="border-top: 3px solid #1565C0">
                    <v-card-text>
                        <div id="container2" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card style="border-top: 3px solid #1565C0">
                    <v-card-text>
                        <div id="container3" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card style="border-top: 3px solid #1565C0">
                    <v-card-text>
                        <div id="container4" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card style="border-top: 3px solid #1565C0">
                    <v-card-text>
                        <div id="container5" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card style="border-top: 3px solid #1565C0">
                    <v-card-text>
                        <div id="container6" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card style="border-top: 3px solid #1565C0">
                    <v-card-text>
                        <div id="container7" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        data() {
            return {
                breadcumbs: [
                    {
                    text: 'Sunrise Steel',
                    disabled: false,
                    href: '/admin/sr',
                    },
                    {
                    text: 'Sales Marketing Analysis',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                    {
                    text: 'Sc & Dispatch Analysis',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                ],
                snackbar: {
                    color: null,
                    icon: null,
                    mode: null,
                    position: "top",
                    text: null,
                    timeout: 7500,
                    title: null,
                    visible: false
                },
                loading: false,
                saless: [],
                sales: '',
                customers: [
                    {
                        label: 'All',
                        value: 'All'
                    },
                    {
                        label: 'KKA',
                        value: 'KKA'
                    },
                    {
                        label: 'Non KKA',
                        value: 'Non KKA'
                    }
                ],
                customer: '',
                search: null,
                order_id: '',
                loadingOrder: false,
                items:[],
                date_from: '',
                modal: false,
                date_to: '',
                modal_to: false, 
                brands: [],
                brand: '',
                coat_masss: [],
                coat_mass: '',
                grades: [],
                grade: '',
                thickness: [],
                thick: '',
                widths: [],
                width: '',
                colours: [],
                colour: '',
                qualities: [],
                quality: '',               
            }
        },
        mounted(){
            this.$store.dispatch('setOverlay', false)
            this.customer = 'All'
            this.getBrand()
            this.getCoatMass()
            this.getGrade()
            this.getThick()
            this.getWidth()
            this.getColour()
            this.getQuality()
        },
        methods:{
            close(){
                this.dialog = false
            },
            async getBrand(){
                await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/brand`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.brands = res.data
                });
            },
            async getCoatMass(){
                await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/coat_mass`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.coat_masss = res.data
                });
            },
            async getGrade(){
                await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/grade`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.grades = res.data
                });
            },
            async getThick(){
                await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/thicks?commodity_id=GLV`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.thickness = res.data
                });
            },
            async getWidth(){
                await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/width?commodity_id=GLV`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.widths = res.data
                });
            },
            async getColour(){
                await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/colour?brand=${this.brand ? this.brand : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.colours = res.data
                });
            },
            async getQuality(){
                await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/quality?commodity_id=GLV`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.qualities = res.data
                });
            },
            async submit(){
                this.$store.dispatch('setOverlay', true)

                var start = this.date_from ? new Date(this.date_from).toISOString().substr(0, 7).replace(/-/g,"") : ''
                var end = this.date_to ? new Date(this.date_to).toISOString().substr(0, 7).replace(/-/g,"") : ''

                await axios.get(`${process.env.VUE_APP_URL}/api/sr/shipment_analysis?customer=${this.customer ? this.customer : ''}&start=${start}&end=${end}&brand=${this.brand}&coat=${this.coat_mass}&grade=${this.grade ? this.grade : ''}&thick=${this.thick ? this.thick : ''}&width=${this.width ? this.width : ''}&colour=${this.colour ? this.colour : ''}&quality=${this.quality ? this.quality : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    console.log(res.data);
                    var dt1 = [];
                    var dt2 = [];
                    var label = 'Cust. Category: ' + this.customer;

                    for (var i = 0; i < res.data.bySC.length; i++) {
                        dt1.push({ label: res.data.bySC[i].periode , y: parseFloat(res.data.bySC[i].total_wgt)});
                    }

                    for (var i = 0; i < res.data.byDeliv.length; i++) {
                        dt2.push({ label: res.data.byDeliv[i].periode , y: parseFloat(res.data.byDeliv[i].total_wgt)});
                    }

                    var container = 'container1';
                    var title = this.customer +" Summary";

                    this.getChart(dt1, dt2, container, title, label);

                    $('html, body').animate({
                        scrollTop: $("#sumScShipmentAnalysis").offset().top
                    }, 1200)

                    this.$store.dispatch('setOverlay', false)

                });
            },
            getChart(dt1, dt2, container, title, label){
                var chart = new CanvasJS.Chart(container, {
                    animationEnabled: true,
                    theme: "light2",
                    exportEnabled: true,
                    title: {
                        text: title,
                        fontFamily: "Calibri",
                        fontSize: 20
                    },
                    exportEnabled: true,
                    subtitles:[
                        {
                            text: label,
                            //Uncomment properties below to see how they behave
                            fontFamily: "Calibri",
                            fontColor: "red",
                            fontSize: 12
                        }
                    ],
                    axisY: {
                        // scaleBreaks: {
                        //     autoCalculate: true,
                        //     type: "wavy"
                        // },
                        crosshair: {
                            enabled: true,
                            snapToDataPoint: true
                        },
                        title: "TON",
                        labelFormatter: this.addSymbols,
                    },
                    toolTip:{
                        shared:true
                    },
                    legend: {
                        // cursor: "pointer",
                        itemclick: this.toggleDataSeries,
                        fontSize: 11
                    },
                    data: [
                    {
                        type: "column",
                        click: this.onClick1,
                        name: "Total SC (by ReqShipDate)",
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        indexLabelPlacement: "outside",  
                        indexLabelOrientation: "horizontal",
                        color: "#75cfb8",
                        // yValueFormatString: "#,###,,,.##",
                    },
                    {
                        type: "column",
                        click: this.onClick2,
                        name: "Total Dispatch",
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        indexLabelPlacement: "outside",  
                        indexLabelOrientation: "horizontal",
                        color: "#ed6663",
                        // yValueFormatString: "#,###,,,.##",
                    }]
                });

                chart.options.data[0].dataPoints = dt1;
                chart.options.data[1].dataPoints = dt2;
                this.showDefaultText(chart, "No Data Found!");
                var x = window.matchMedia("(max-width: 991px)");
                if (x.matches) {

                    for(var i = 0; i < chart.options.data.length; i++){
                        chart.options.data[i].indexLabelFontSize = 8;
                    }
                    chart.render();
                }
                chart.render();
            },

            addSymbols(e) {
                var suffixes = ["", "K", "M", "B"];
                var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

                if(order > suffixes.length - 1)
                    order = suffixes.length - 1;

                var suffix = suffixes[order];
                return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
            },

            toggleDataSeries(e) {
                if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                    e.dataSeries.visible = false;
                } else {
                    e.dataSeries.visible = true;
                }
                e.chart.render();
            },

            toggleDataPointVisibility(e) {
                if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                    e.dataPoint.y = e.dataPoint.actualYValue;
                    e.dataPoint.actualYValue = null;
                    e.dataPoint.indexLabelFontSize = null;
                    e.dataPoint.indexLabelLineThickness = null;
                    e.dataPoint.legendMarkerType = "circle";
                } 
                else {
                    e.dataPoint.actualYValue = e.dataPoint.y;
                    e.dataPoint.y = 0;
                    e.dataPoint.indexLabelFontSize = 0;
                    e.dataPoint.indexLabelLineThickness = 0; 
                    e.dataPoint.legendMarkerType = "cross";
                }
                    e.chart.render();
            },

            showDefaultText(chart, text) {
                var dataPoints = chart.options.data[0].dataPoints;
                var isEmpty = !(dataPoints && dataPoints.length > 0);

                if (!isEmpty) {
                    for (var i = 0; i < dataPoints.length; i++) {
                    isEmpty = !dataPoints[i].y;
                    if (!isEmpty)
                        break;
                    }
                }

                if (!chart.options.subtitles)
                    chart.options.subtitles = [];
                if (isEmpty) {
                    chart.options.subtitles.push({
                    text: text,
                    verticalAlign: 'center',
                    });
                    chart.options.data[0].showInLegend = false;
                } else {
                    chart.options.data[0].showInLegend = true;
                }
            },
            async onClick1(e){
                this.$store.dispatch('setOverlay', true)

                await axios.get(`${process.env.VUE_APP_URL}/api/sr/shipment_analysis/showSc?customer=${this.customer ? this.customer : ''}&periode=${e.dataPoint.label}&brand=${this.brand}&coat=${this.coat_mass}&grade=${this.grade ? this.grade : ''}&thick=${this.thick ? this.thick : ''}&width=${this.width ? this.width : ''}&colour=${this.colour ? this.colour : ''}&quality=${this.quality ? this.quality : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    console.log(res.data);

                    var param = e.dataPoint.label;
                    var label_detail = "Periode: "+param;

                    for (var i = 0; i < res.data.qWidth.length; i++) {

                        var dt_detail =  [];

                        for (var i = 0; i < res.data.qWidth.length; i++) {

                            dt_detail.push({  label: res.data.qWidth[i].width,  y: parseInt(res.data.qWidth[i].total_wgt), legendText: res.data.qWidth[i].width });

                        }
                        var container_detail = 'container2';
                        var title_detail = 'by Width'
                        this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                    }

                    for (var i = 0; i < res.data.qThick.length; i++) {

                        var dt_detail =  [];

                        for (var i = 0; i < res.data.qThick.length; i++) {

                            dt_detail.push({  label: res.data.qThick[i].thick,  y: parseInt(res.data.qThick[i].total_wgt), legendText: res.data.qThick[i].thick });

                        }
                        var container_detail = 'container3';
                        var title_detail = 'by Thickness'
                        this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                    }

                    for (var i = 0; i < res.data.qCoatMass.length; i++) {

                        var dt_detail =  [];

                        for (var i = 0; i < res.data.qCoatMass.length; i++) {

                            dt_detail.push({  label: res.data.qCoatMass[i].coat_mass,  y: parseInt(res.data.qCoatMass[i].total_wgt), legendText: res.data.qCoatMass[i].coat_mass });

                        }
                        var container_detail = 'container4';
                        var title_detail = 'by AS/AZ'
                        this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                    }

                    for (var i = 0; i < res.data.qGrade.length; i++) {

                        var dt_detail =  [];

                        for (var i = 0; i < res.data.qGrade.length; i++) {

                            dt_detail.push({  label: res.data.qGrade[i].grade_id,  y: parseInt(res.data.qGrade[i].total_wgt), legendText: res.data.qGrade[i].grade_id });

                        }
                        var container_detail = 'container5';
                        var title_detail = 'by Grade'
                        this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                    }

                    for (var i = 0; i < res.data.qQuality.length; i++) {

                        var dt_detail =  [];

                        for (var i = 0; i < res.data.qQuality.length; i++) {

                            dt_detail.push({  label: res.data.qQuality[i].quality_id,  y: parseInt(res.data.qQuality[i].total_wgt), legendText: res.data.qQuality[i].quality_id });

                        }
                        var container_detail = 'container6';
                        var title_detail = 'by Quality'
                        this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                    }

                    for (var i = 0; i < res.data.qColor.length; i++) {

                        var dt_detail =  [];

                        for (var i = 0; i < res.data.qColor.length; i++) {

                            dt_detail.push({  label: res.data.qColor[i].color_name,  y: parseInt(res.data.qColor[i].total_wgt), legendText: res.data.qColor[i].color_name });

                        }
                        var container_detail = 'container7';
                        var title_detail = 'by Color'
                        this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                    }

                    this.$store.dispatch('setOverlay', false)

                });
            },
            async onClick2(e){
                this.$store.dispatch('setOverlay', true)

                await axios.get(`${process.env.VUE_APP_URL}/api/sr/shipment_analysis/showDeliv?customer=${this.customer ? this.customer : ''}&periode=${e.dataPoint.label}&brand=${this.brand}&coat=${this.coat_mass}&grade=${this.grade ? this.grade : ''}&thick=${this.thick ? this.thick : ''}&width=${this.width ? this.width : ''}&colour=${this.colour ? this.colour : ''}&quality=${this.quality ? this.quality : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    console.log(res.data);

                    var param = e.dataPoint.label;
                    var label_detail = "Periode: "+param;

                    for (var i = 0; i < res.data.qWidth.length; i++) {

                        var dt_detail =  [];

                        for (var i = 0; i < res.data.qWidth.length; i++) {

                            dt_detail.push({  label: res.data.qWidth[i].width,  y: parseInt(res.data.qWidth[i].total_wgt), legendText: res.data.qWidth[i].width });

                        }
                        var container_detail = 'container2';
                        var title_detail = 'by Width'
                        this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                    }

                    for (var i = 0; i < res.data.qThick.length; i++) {

                        var dt_detail =  [];

                        for (var i = 0; i < res.data.qThick.length; i++) {

                            dt_detail.push({  label: res.data.qThick[i].thick,  y: parseInt(res.data.qThick[i].total_wgt), legendText: res.data.qThick[i].thick });

                        }
                        var container_detail = 'container3';
                        var title_detail = 'by Thickness'
                        this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                    }

                    for (var i = 0; i < res.data.qCoatMass.length; i++) {

                        var dt_detail =  [];

                        for (var i = 0; i < res.data.qCoatMass.length; i++) {

                            dt_detail.push({  label: res.data.qCoatMass[i].coat_mass,  y: parseInt(res.data.qCoatMass[i].total_wgt), legendText: res.data.qCoatMass[i].coat_mass });

                        }
                        var container_detail = 'container4';
                        var title_detail = 'by AS/AZ'
                        this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                    }

                    for (var i = 0; i < res.data.qGrade.length; i++) {

                        var dt_detail =  [];

                        for (var i = 0; i < res.data.qGrade.length; i++) {

                            dt_detail.push({  label: res.data.qGrade[i].grade_id,  y: parseInt(res.data.qGrade[i].total_wgt), legendText: res.data.qGrade[i].grade_id });

                        }
                        var container_detail = 'container5';
                        var title_detail = 'by Grade'
                        this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                    }

                    for (var i = 0; i < res.data.qQuality.length; i++) {

                        var dt_detail =  [];

                        for (var i = 0; i < res.data.qQuality.length; i++) {

                            dt_detail.push({  label: res.data.qQuality[i].quality_id,  y: parseInt(res.data.qQuality[i].total_wgt), legendText: res.data.qQuality[i].quality_id });

                        }
                        var container_detail = 'container6';
                        var title_detail = 'by Quality'
                        this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                    }

                    for (var i = 0; i < res.data.qColor.length; i++) {

                        var dt_detail =  [];

                        for (var i = 0; i < res.data.qColor.length; i++) {

                            dt_detail.push({  label: res.data.qColor[i].color_name,  y: parseInt(res.data.qColor[i].total_wgt), legendText: res.data.qColor[i].color_name });

                        }
                        var container_detail = 'container7';
                        var title_detail = 'by Color'
                        this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                    }

                    this.$store.dispatch('setOverlay', false)

                });
            },
            getPieChart(dt_detail, container_detail, title_detail, label_detail){
                var pie_chart = new CanvasJS.Chart(container_detail, {
                    animationEnabled: true,
                    exportEnabled: true,
                    theme: "light2",
                    exportEnabled: true,
                    title: {
                        text: title_detail,
                        fontFamily: "Calibri",
                        fontSize: 20
                    },
                    exportEnabled: true,
                    subtitles:[
                        {
                            text: label_detail,
                            //Uncomment properties below to see how they behave
                            fontFamily: "Calibri",
                            fontColor: "red",
                            fontSize: 12
                        }
                    ],
                    legend: {
                        itemclick: this.toggleDataPointVisibility
                    },
                    data: [{

                        type: "pie",
                        percentFormatString: "#0.##",
                        indexLabel: "{label} #percent%",
                        indexLabelFontSize: 12,

                    }]
                });

                pie_chart.options.data[0].dataPoints = dt_detail;
                this.showDefaultText(pie_chart, "No Data Found!");
                var x = window.matchMedia("(max-width: 991px)");

                if (x.matches) {

                    for(var i = 0; i < pie_chart.options.data.length; i++){
                        pie_chart.options.data[i].indexLabelFontSize = 6;
                    }
                    pie_chart.render();
                }
                pie_chart.render();
            }
        },
        watch: {
        }
    }
    </script>
    